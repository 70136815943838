<template>
  <div>
    <div class="cameraImg" style="display: none">
      <div style="display: none">
        <canvas width="200px" height="150px" ref="canvas"></canvas>
      </div>
    </div>
    <div class="mask" v-show="flagss">
      <div>
        <img class="imgs" src="../assets/icon.png" alt="" />
        <div class="popup">
          <div class="title1">人脸识别认证</div>
          <div class="content1">
            在您的学习过程中我们将对您进行学习身份进行核验，届时会人脸识别的方式进行核验。
          </div>
          <el-checkbox style="margin-left: 30px" v-model="checked"
            >同意《职培通在线教育云平台学员核验规定》</el-checkbox
          >
          <div class="dian" @click="normal1">开始认证</div>
        </div>
      </div>
    </div>

    <div class="human_mask" v-show="flags">
      <div>
        <img class="imgs" src="../assets/icon.png" alt="" />
        <div class="popup">
          <div class="title1">人脸识别</div>
          <video class="head" ref="video"></video>
          <div class="head_1"></div>
          <div class="dian" id="snap" @click="getImg">开始核验</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  props: ["type"],
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
      classid: (state) => state.classid,
      url: (state) => state.url,
      school: (state) => state.school,
      studyType: (state) => state.studyType,
      details: (state) => state.details,
      det: (state) => state.det,
      human: (state) => state.human,
    }),
  },
  data() {
    return {
      aaa: true,
      flagss: false,
      checked: false,
      flags: false,
    };
  },
  methods: {
    ...mapActions(["getpictureUpload_x", "faceAuth", "getUploadImg"]),
    //父增加掉子组件
    control() {
      this.flagss = true;
    },
    // 勾选协议
    normal1() {
      if (this.checked == false) {
        this.$message({
          message: "请勾选协议",
          type: "warning",
        });
      } else {
        this.flagss = false;
        this.openCamera();
        this.flags = true;

        return;
      }
    },
    //canvas中展示截取的图像
    getImg() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let that = this;
      var canvas = this.$refs.canvas;
      var context = canvas.getContext("2d");
      context.drawImage(this.video, 0, 0, 200, 150);
      var image = canvas.toDataURL("image/png");
      var timestamp = Date.parse(new Date()); //获取时间戳，防止图像重名
      var img = this.dataURLtoFile(image, timestamp + ".png"); //使用png的图像进行名称拼接
      console.log(img, "this.imageUrl");

      // this.video.srcObject.getTracks()[0].stop();
      // return;
      let uploadData = new FormData();
      uploadData.append("file", img);
      this.getUploadImg(uploadData).then((res) => {
        that
          .faceAuth({
            student_id: that.userInfo.id, //用户名
            image: res.responseBody, //人脸图片
            genre: that.type, //1. 学习核验 2. 考试核验
            class_id: that.details.id,
          })
          .then((ress) => {
            loading.close();
            if (ress.successCode == 200) {
              ///通过成功
              that.$message.success("验证成功");
              that.$store.commit("STE_HUMAN", 0);
              that.video.srcObject.getTracks()[0].stop();
              that.flags = false;
            } else {
              ///失败
              that.$message.error(ress.message);
              setTimeout(() => {
                that.video.srcObject.getTracks()[0].stop();
                that.flags = false;

                // that.$router.push("/Layout/mynterpretation");
              }, 1000);
            }
          });
      });
      // var imgBase64;
      // var reader = new FileReader();
      // reader.readAsDataURL(img);
      // reader.onload = function () {
      //   imgBase64 = this.result; //获取到base64格式图片
      //   console.log("imgBase64", imgBase64);
      //   that
      //     .getpictureUpload_x({
      //       img: imgBase64,
      //     })
      //     .then((res) => {
      //       if (res.successCode == 200) {
      //       }
      //     });
      // };
    },
    // 打开摄像头
    openCamera() {
      this.$nextTick(() => {
        var video = this.$refs.video;
        this.video = video;
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          navigator.mediaDevices
            .getUserMedia({
              video: true,
            })
            .then(function (stream) {
              MediaStreamTrack =
                typeof stream.stop === "function"
                  ? stream
                  : stream.getTracks()[1];
              video.srcObject = stream;
              video.play();
            })
            .catch(function (err) {
              // console.log(err);
            });
        } else if (navigator.getMedia) {
          navigator
            .getMedia({
              video: true,
            })
            .then(function (stream) {
              console.log(stream);
              MediaStreamTrack = stream.getTracks()[1];
              video.src = window.webkitURL.createObjectURL(stream);
              video.play();
            })
            .catch(function (err) {
              console.log(err);
            });
        }
      });
    },
    //图片转base64位
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      var file = new File([u8arr], filename, { type: mime });
      return new File([u8arr], filename, { type: mime });
    },
  },
};
</script>

<style lang="scss" scoped>
.video-js .vjs-icon-placeholder {
  width: 100%;
  height: 100%;
  display: block;
}
::v-deep .video {
  width: 900px;
  height: 500px;

  .controlbar_btn {
    display: none !important;
  }
  .m-popover {
    display: none !important;
  }
}

.mask1 {
  position: fixed;
  z-index: 999;

  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  .imgs {
    display: block;
    width: 200px;
    margin: 0 auto;
    // vertical-align: middle;
  }
  .img {
    width: 40px;
    height: 40px;
    display: block;
    margin: 15px auto;
  }
  .popup {
    position: relative;
    z-index: 100;
    width: 227px;
    height: 143px;
    background: #ffffff;
    border-radius: 16px;
    .title1 {
      text-align: center;
      padding: 20px;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #0067ff;
      line-height: 26px;
      span {
        font-size: 18px;
      }
      .span {
        font-weight: 500;
        font-size: 16px;
        color: #000;
      }
    }
    .xian {
      width: 100%;
      height: 2px;
      background-color: #eaeaea;
    }
    .dian {
      cursor: pointer;
      margin-top: 15px;
      text-align: center;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #0067ff;
    }
  }
}
.human_mask {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  .popup {
    position: relative;
    width: 414px;
    height: 453px;
    background: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(147, 147, 147, 0.5);
    border-radius: 15px;
    .title1 {
      padding-top: 30px;
      text-align: center;
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #008bd5;
      line-height: 33px;
    }
    .head {
      display: block;
      margin: 0 auto;
      width: 200px;
      height: 200px;
      border-radius: 50%;
    }
    // .head_1 {
    //   position: absolute;
    //   width: 100px;
    //   height: 100px;
    //   left: 0;
    //   top: 0;
    // }
    .dian {
      border-top: 2px solid #eaeaea;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 50px;
      width: 100%;
      line-height: 50px;
      margin-top: 15px;
      text-align: center;

      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #0067ff;
      cursor: pointer;
    }
  }
}
.stop_mask {
  position: fixed;
  z-index: 999;

  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  .imgs {
    display: block;
    width: 200px;
    margin: 0 auto;
    // vertical-align: middle;
  }
  .img {
    width: 40px;
    height: 40px;
    display: block;
    margin: 15px auto;
  }
  .popup {
    position: relative;
    z-index: 100;
    width: 227px;
    height: 143px;
    background: #ffffff;
    border-radius: 16px;
    .title1 {
      text-align: center;
      padding: 20px;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #0067ff;
      line-height: 26px;
      span {
        font-size: 18px;
      }
      .span {
        font-weight: 500;
        font-size: 16px;
        color: #000;
      }
    }
    .xian {
      width: 100%;
      height: 2px;
      background-color: #eaeaea;
    }
    .dian {
      cursor: pointer;
      margin-top: 15px;
      text-align: center;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #0067ff;
    }
  }
}
.mask {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  .imgs {
    display: block;
    width: 300px;
    margin: 0 auto;
    // vertical-align: middle;
  }
  .popup {
    position: relative;
    z-index: 100;
    width: 414px;
    height: 377px;
    background: #ffffff;
    border-radius: 16px;
    .title1 {
      padding-top: 30px;
      text-align: center;
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #008bd5;
      line-height: 33px;
    }
    .content1 {
      margin: 30px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
    }
    .dian {
      border-top: 2px solid #eaeaea;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 50px;
      width: 100%;
      line-height: 50px;
      margin-top: 15px;
      text-align: center;

      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #0067ff;
      cursor: pointer;
    }
  }
}
.loadmore {
  cursor: pointer;
  font-size: 16px;
  text-align: center;
}
.title {
  margin-top: 68px;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 26px;
  display: flex;
  .item {
    cursor: pointer;
    position: relative;
    margin-right: 52px;
  }
  .active {
    &::before {
      position: absolute;
      display: inline-block;
      content: "";
      width: 108px;
      height: 4px;
      background: #137cfb;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
.list {
  display: flex;
  flex-wrap: wrap;

  .active {
    color: #137cfb !important;
    border: 2px solid #137cfb !important;
    background: #f5fbff;
  }
  .item {
    cursor: pointer;
    position: relative;
    margin-bottom: 17px;
    margin-right: 18px;
    padding: 10px;
    box-sizing: border-box;
    width: 160px;
    height: 75px;
    background: #fff;
    border-radius: 8px;
    border: 2px solid #e7e7e7;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    overflow: hidden;
    line-height: 20px;

    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .wc {
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>

.mask {
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  .imgs {
    display: block;
    width: 300px;
    margin: 0 auto;
  }
  .popup {
    position: relative;
    z-index: 100;
    width: 414px;
    height: 377px;
    background: #ffffff;
    border-radius: 16px;
    .title1 {
      padding-top: 30px;
      text-align: center;
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #008bd5;
      line-height: 33px;
    }
    .content1 {
      margin: 30px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
    }
    .dian {
      border-top: 2px solid #eaeaea;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 50px;
      width: 100%;
      line-height: 50px;
      margin-top: 15px;
      text-align: center;

      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #0067ff;
      cursor: pointer;
    }
  }
}
</style>