export default {
  //登录信息
  SET_USERINFO(state, data) {
    state.userInfo = data;
    //state.userInfo.username = state.userInfo.phone;
  },
  //班级ID
  SET_CLASSID(state, data) {
    state.classid = data;
  },
  //修改登录信息--头像
  SET_USERINFOEDIT(state, data) {
    state.userInfo.headpic = data;
    let user = state.userInfo;
    state.userInfo = "";
    state.userInfo = user;
  },
  //微信手机号绑定
  SET_WX(state, data) {
    state.wx = data;
  },
  SET_SCHOOL(state, data) {
    state.school = data; //true 学校页
  },
  //支付类型
  SET_PAYTYPE(state, data) {
    state.payType = data;
  },
  //清空登录信息
  SET_RESETUSER(state, data) {
    state.userInfo = "";
  },
  SET_STUDYTYPE(state, data) {
    state.studyType = data;
  },
  //保存当前路由信息
  SET_ROUTER(state, data) {
    state.studyLayout.router = data;
  },
  //登录拦截之前的路由
  SET_ROUTERTO(state, data) {
    state.routerTo = data;
  },
  //个人中心--我的课程
  SET_MYLESSONLIST(state, data) {
    state.setting.MyLessonList = data;
  },
  //首页--选课
  SET_HOME(state, data) {
    state.home = data;
  },
  //详情
  SET_DETAILS(state, data) {
    state.details = data;
  },
  //班级详情
  SET_DET(state, data) {
    state.det = data;
  },
  //修改练答index
  SET_TABINDEX(state, data) {
    state.tabsindex = data;
  },
  SET_STUDYDES(state, data) {
    state.studyDes = data;
  },
  STE_HUMAN(state, data) {
    state.human = data;
  },
  SET_HUMAN_TYPE(state, data) {
    state.human_type = data;
  },
  //学习验证
  SET_LEARNING(state, data) {
    state.learning = data;
  },
  SET_forcetoexit(state, data) {
    state.forcetoexit = data;
  },
  SET_wwy(state, data) {
    state.wwy = data;
  },
  SET_exam(state, data) {
    state.exam = data;
  },
  SET_video_shoot(state, data) {
    let str = state.video_shoot.findIndex(item => {
      return item.id == data.id
    });
    // 判断相同id不插入数组，直接更改原数据
    if (str != -1) {
      state.video_shoot[str].time = parseInt(state.video_shoot[str].time) > parseInt(data.time) ? state.video_shoot[str].time : data.time
      return
    }
    if (state.video_shoot.length == 2) {
      state.video_shoot.shift()
      state.video_shoot.push(data)
    } else {
      state.video_shoot.push(data)
    }
  },
  SET_video_shoot_clear(state, data) {
    state.video_shoot = [];
  },
  //跨天登录清除视频缓存
  SET_video_shoot_time(state, data) {
    let str = new Date(+new Date() + 8 * 3600 * 1000)
      .toJSON()
      .substr(0, 10)
      .replace("T", " ")
    console.log(str);
    // let str = '2022-08-03'
    if (state.video_shoot_time == null) {
      state.video_shoot_time = str
    } else if (state.video_shoot_time == str) {
      state.video_shoot_time = str
    } else if (state.video_shoot_time != str) {
      state.video_shoot_time = str
      state.video_shoot = [];
    }
  },
};
