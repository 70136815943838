import Http from "./axios.js";

import store from "../store";
import QS from "qs";
//http://frontend.zpt.udao.sh.cn      https://mp2api.zpton.com
//老接口
const ajaxUrl =
  process.env.NODE_ENV === "development" ? // 测试接口地址
  "https://zptonv3vxapiv3.udao.sh.cn" : // 线上接口地址
  "https://zptonv3vxapiv3.udao.sh.cn";
//新接口
const ajaxUrl1 =
  process.env.NODE_ENV === "development" ? // 测试接口地址
  "https://frontend.zpt.udao.sh.cn"
  //老接口"
  : // 线上接口地址
  "https://frontend.zpt.udao.sh.cn";
export default {
  json(url, data, config) {
    return Http.get(url, data, config);
  },
  post(url, data, config) {
    url = ajaxUrl + url;
    return Http.post(url, QS.stringify(data), config);
  },
  postB(url, data, config) {
    url = ajaxUrl1 + url;
    return Http.post(url, QS.stringify(data), config);
  },
  postC(url, data, config) {
    url = ajaxUrl1 + url;
    data = {
      ...data,
      token: store.state.userInfo.token || '',
      username: store.state.userInfo.username,
    }
    return Http.post(url, QS.stringify(data), config);
  },
  //图片上传
  upload(url, data) {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    return Http.post(ajaxUrl1 + url, data, config);
  },
  get(url, params, config) {
    const getConfig = {};
    var token = store.state.userInfo.token;
    url = ajaxUrl + url + "?Access_Token=" + token;
    if (params) {
      Object.assign(getConfig, {
        params,
      });
    }
    if (config) Object.assign(getConfig, config);
    return Http.get(url, getConfig);
  },
  getB(url, params, config) {
    const getConfig = {};
    var token = store.state.userInfo.token;
    url = ajaxUrl1 + url
    if (params) {
      Object.assign(getConfig, {
        params
      });
    }
    if (config) Object.assign(getConfig, config);
    return Http.get(url, getConfig);
  },
  put(url, data, config) {
    return Http.put(ajaxUrl + url, data, config);
  },

  delete(url, params, config) {
    const delConfig = {};
    if (params) {
      Object.assign(delConfig, {
        params,
      });
    }
    if (config) Object.assign(delConfig, config);

    return Http.delete(url, delConfig);
  },
};