//数据源定义
const state = {
  url: "https://bes.zpton.com", //音频视频拼接地址
  http: "https://mp.zpton.com", //服务器地址 用于微信登录 和支付的返回地址拼接
  //登录信息
  userInfo: {
    // headpic: "https://bes.zpton.com/Public/Home/images/index_nr_1.jpg",
    // id: 109118,
    // identity: null,
    // kindof: "1",
    // password: "4ce984678341dd0d708de81d3ad9ab29",
    // phone: "17683801735",
    // realname: null,
    // sex: null,
    // unionid: null,
    // username: "17683801735",
    // yz: 0,
  },
  wx: {},
  classid: "",
  appid: "wx57008646276de278",
  school: "", //判断是否是学校过来的 空不是
  payType: "1", //支付类型
  //主页面路由监听参数
  studyLayout: {
    router: null,
  },
  //个人中心模块
  setting: {
    MyLessonList: [], //我的课程列表
  },
  //首页--选课
  home: null,
  routerTo: "", //登录拦截之前的路由
  details: "", //课程详情
  det: {},
  tabsindex: "1", //练答页面选项卡位置
  studyType: "1", //1去学习  2免费试学  3付费
  studyDes: {}, //存学习章节目录及index做分页
  learning: 1,//1需要验证2已通过验证
  human: 0,//数字10需要人脸识别其他不需要
  human_type: 1,//1.人脸识别后进入视频页面，2人脸识别后进入音频页面
  forcetoexit: null,
  wwy: 1,// 1.普通课2.网易课3.普通课检查课程
  video_shoot: [],
  video_shoot_time: null,

};
export default state;
