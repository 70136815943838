<template>
  <div></div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: {
    type: {
      required: true,
    },
    iszp: {
      default: 0,
    },
    //停止计时结束时间，中间没有停止时间为0
    tz_time: {
      default: 0,
    },
  },
  computed: {
    ...mapState({
      details: (state) => state.details,
      userInfo: (state) => state.userInfo,
      url: (state) => state.url,
      school: (state) => state.school,
      studyType: (state) => state.studyType,
      classid: (state) => state.classid,
    }),
  },
  data() {
    return {
      str: null,
      end: null,
      flag: true,
    };
  },
  methods: {
    ...mapActions(["screenCuttingDuration_x"]),
    //同步计时
    timing() {
      let that = this;
      this.end = null;
      this.end = new Date();
      let date3 = null;
      if (that.tz_time > 0) {
        date3 = that.tz_time - that.str.getTime();
      } else {
        date3 = that.end.getTime() - that.str.getTime();
      }
      if (date3 < 1000) return;
      that
        .screenCuttingDuration_x({
          user_id: that.userInfo.id,
          class_id: that.classid,
          video_time:
            (date3 / 1000).toFixed(0) > 10800
              ? 10800
              : (date3 / 1000).toFixed(0),
          source: that.type,
          video_id: "", //视频id
          is_finished: "1", //是否学完 1-完成 2-未完成
          last_viewing_time: "",
        })
        .then((res) => {
          that.time = 0;
          that.str = new Date();
          if (that.type == 1) {
            if (res.successCode == 200) {
              this.$notify({
                title: "提示",
                message: "页面时间记录成功",
                type: "success",
              });
            }
          }
        });
    },
    // 页面离开时间结算
    gettime() {
      let that = this;
      this.end = null;
      this.end = new Date();
      let date3 = null;
      // date3 = this.end.getTime() - this.str.getTime();
      if (this.tz_time > 0) {
        date3 = this.tz_time - this.str.getTime();
      } else {
        date3 = this.end.getTime() - this.str.getTime();
      }
      // document.removeEventListener("beforeunload", that.gettime, false);
      if (date3 < 1000) return;
      if (this.flag) {
        this.screenCuttingDuration_x({
          user_id: that.userInfo.id,
          class_id: that.classid,
          video_time:
            (date3 / 1000).toFixed(0) > 10800
              ? 10800
              : (date3 / 1000).toFixed(0),
          source: that.type,
          video_id: "", //视频id
          is_finished: "1", //是否学完 1-完成 2-未完成
          last_viewing_time: "",
        });
      }
    },
    // 清除window事件
    createBeforeunloadHandler() {
      let that = this;
      window.addEventListener("beforeunload", that.gettime, false);
    },
    // 清除window事件
    destroyedBeforeunloadHandler() {
      let that = this;
      document.removeEventListener("beforeunload", that.gettime, false);
    },
  },

  mounted() {
    let that = this;
    this.str = null;
    this.str = new Date();
    this.createBeforeunloadHandler();
  },
  beforeDestroy() {
    let that = this;
    this.end = null;
    this.end = new Date();
    let date3 = null;
    // date3 = this.end.getTime() - this.str.getTime();
    console.log(this.tz_time, "this.tz_time");
    if (this.tz_time > 0) {
      date3 = this.tz_time - this.str.getTime();
    } else {
      date3 = this.end.getTime() - this.str.getTime();
    }
    if (date3 < 1000) return;
    this.destroyedBeforeunloadHandler();
    this.flag = false;
    this.screenCuttingDuration_x({
      user_id: that.userInfo.id,
      class_id: that.classid,
      video_time:
        (date3 / 1000).toFixed(0) > 10800 ? 10800 : (date3 / 1000).toFixed(0),
      source: that.type,
      video_id: "", //视频id
      is_finished: "1", //是否学完 1-完成 2-未完成
      last_viewing_time: "",
    }).then((res) => {
      if (that.type == 1) {
        if (res.successCode == 200) {
          this.$notify({
            title: "提示",
            message: "页面时间记录成功",
            type: "success",
          });
        }
      }
    });

    //必须内存地址相同才能移出方法
  },
};
</script>

<style lang='scss' scoped>
</style>