<template>
  <div id="app">
    <headers v-if="show"></headers>
    <advertising></advertising>
    <headers1 v-if="show1"></headers1>
    <router-view />
    <footers v-if="show"></footers>
    <footers v-if="show1"></footers>
    <div class="mask" v-if="isShow">
      <div class="content">
        <div style="text-align: center">
          <img src="../src/assets/duanxian.png" alt="" />
        </div>
        <div class="text">亲，由于长时间没使用我</div>
        <div class="text1">我将退出页面,请<span>重新登录</span>继续学习</div>
      </div>
    </div>
    <div class="mask1" v-if="show_popup">
      <div>
        <img class="img" src="../src/assets/icon.png" alt="" />
        <div class="pooup">
          <div class="title">版本提醒</div>
          <div class="con">
            学员您好！ 如果您是老版本官网的学员，请去老版本官网完成您的学习。
          </div>
          <div class="btn">
            <div class="btn_1" @click="show_popup = false">取消</div>
            <div class="btn_2" @click="goxinwz">去老版本官网学习</div>
          </div>
        </div>
        <img
          class="imgs"
          @click="show_popup = false"
          src="../src/assets/close.png"
          alt=""
        />
      </div>
    </div>
    <div class="masck" v-show="centerDialogVisible">
      <div class="pop">
        <div class="title">浏览器兼容提示</div>
        <div class="content">请选择Chrome浏览器更好的体验</div>
      </div>
    </div>
    <!-- <el-dialog
      title="浏览器兼容提示"
      :visible.sync="centerDialogVisible"
      width="30%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      center
    >
      <span style="font-size: 24px; align-items: center"
        >请选择Chrome浏览器更好的体验</span
      >
    </el-dialog> -->
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      show_popup: true,
      centerDialogVisible: false,
      show: true,
      show1: true,
      time: null,
      time1: null,
      time2: null,
      time3: null,
      isShow: false,
    };
  },
  mounted() {
    if (!!window.ActiveXObject || "ActiveXObject" in window) {
      //layer.alert("请选择使用Microsoft Edge浏览器或者Chrome浏览器更好的体验。");
      this.centerDialogVisible = true;
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
      forcetoexit: (state) => state.forcetoexit,
    }),
  },
  methods: {
    ...mapActions(["getClassHour", "getLoginOut", "getsignOut_x"]),
    goxinwz() {
      window.location.href = "https://website.zpton.com";
    },
    init() {
      let that = this;
      this.$router.push("/login");
      setTimeout(() => {
        this.getsignOut_x({
          username: this.userInfo.username,
          student_id: this.userInfo.id,
        }).then((res) => {
          that.$store.commit("SET_RESETUSER");
        });
      }, 2000);
    },
  },
  watch: {
    $route(to, form) {
      this.show = to.meta.headershow;
      this.show1 = to.meta.headershow1;
      if (to.meta.headershow1) {
        this.userInfo.random = new Date().getTime();
        this.$store.commit("SET_USERINFO", this.userInfo);
      } else {
        clearTimeout(this.time);
      }
    },
    userInfo: {
      immediate: true,
      deep: true,
      handler(newval) {
        let body = document.body;
        let _than = this;
        clearTimeout(_than.time);
        clearTimeout(_than.time1);
        clearTimeout(_than.time2);
        clearTimeout(_than.time3);
        if (_than.userInfo && _than.userInfo.id) {
          console.log(_than.userInfo && _than.userInfo.id);
          if (_than.time) {
            clearTimeout(_than.time);
          }
          _than.time3 = setTimeout(() => {
            if (_than.forcetoexit) {
              let end = new Date();
              // let arr = _than.forcetoexit + 180 * 60 * 1000;
              // let arr = _than.forcetoexit + 180 * 60 * 1000;
              // let str = arr - end.getTime();
              // // _than.time2 = setInterval(() => {
              // //   str = str - 10000;
              // //   console.log((str - 10 * 60 * 1000) / 1000, "秒后强制退出");
              // // }, 10000);
              // _than.time1 = setTimeout(() => {
              //   _than.$store.commit("SET_forcetoexit", null);
              //   _than.init();
              // }, str - 10 * 60 * 1000);
              let arr = _than.forcetoexit + 170 * 60 * 1000;
              let str = arr - end.getTime();
              // _than.time2 = setInterval(() => {
              //   str = str - 10000;
              //   console.log((str - 10 * 60 * 1000) / 1000, "秒后强制退出");
              // }, 10000);
              console.log(str / 1000, "秒后强制退出");
              _than.time1 = setTimeout(() => {
                _than.$store.commit("SET_forcetoexit", null);
                _than.init();
              }, str);
            }
          }, 100);
          // _than.time = setTimeout(() => {
          //   _than.isShow = true;
          //   setTimeout(() => {
          //     _than.init();
          //     _than.isShow = false;
          //   }, 5000);
          // }, 90 * 60 * 1000);
        }

        // body.onclick = function () {
        //   if (_than.userInfo && _than.userInfo.id) {
        //     clearTimeout(_than.time);

        //     _than.time = setTimeout(() => {
        //       _than.isShow = true;
        //       setTimeout(() => {
        //         _than.init();
        //         _than.isShow = false;
        //       }, 5000);
        //     }, 90 * 60 * 1000);
        //   }
        // };
        // body.onmousemove = function () {
        //   if (_than.userInfo && _than.userInfo.id) {
        //     clearTimeout(_than.time);
        //     _than.time = setTimeout(() => {
        //       _than.isShow = true;
        //       setTimeout(() => {
        //         _than.init();
        //         _than.isShow = false;
        //       }, 5000);
        //     }, 90 * 60 * 1000);
        //   }
        // };
      },
    },
  },
};
</script>

<style lang="scss"  scoped>
#app,
body,
html {
  font-size: 12px;
  color: #222;
  width: 100%;
  margin: 0;
}
.masck {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100000;
  display: flex;
  align-items: center;
  justify-content: center;
  .pop {
    width: 700px;
    height: 450px;
    background-color: #fff;
    border-radius: 30px;
    .title {
      font-size: 18px;
      margin: 50px auto;
      text-align: center;
    }
    .content {
      margin-top: 100px;
      font-size: 32px;
      text-align: center;
    }
  }
}
.mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;

  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  .content {
    background-color: #fff;
    position: absolute;
    width: 500px;
    height: 500px;
    left: 50%;
    top: 50%;
    border-radius: 20px;
    transform: translate(-50%, -50%);
    img {
      margin-top: 30px;
    }
    .text {
      margin-top: 30px;
      text-align: center;
      font-size: 16px;
      color: #000;
      color: #919191;
    }
    .text1 {
      margin-top: 30px;
      text-align: center;
      font-size: 16px;
      color: #000;
      color: #919191;
      span {
        color: #137cfb;
      }
    }
  }
}
.mask1 {
  position: fixed;
  z-index: 100000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;

  .img {
    width: 280px;
    display: block;
    vertical-align: middle;
    margin: 0 auto;
  }
  .imgs {
    display: block;
    margin: 20px auto 0;
  }
  .pooup {
    position: relative;
    overflow: hidden;
    width: 400px;
    height: 240px;
    background: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(147, 147, 147, 0.5);
    border-radius: 15px;
    .title {
      text-align: center;
      margin-top: 29px;
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #008bd5;
      line-height: 33px;
    }
    .con {
      padding: 23px 32px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
    }
    .btn {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      border-top: 1px solid #cfcfcf;
      .btn_1 {
        cursor: pointer;
        text-align: center;
        border-right: 1px solid #cfcfcf;
        width: 50%;
        height: 77px;
        line-height: 77px;
        display: inline-block;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #737373;
      }
      .btn_2 {
        cursor: pointer;

        text-align: center;
        height: 77px;
        line-height: 77px;
        width: 50%;
        display: inline-block;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #0066ff;
      }
    }
  }
}
</style>
