<template>
  <div class="box">
    <div class="advertising">
      <div
        :class="
          type == 1 ? 'left active1' : type == 2 ? 'left active2' : 'left'
        "
        v-show="type != 3"
      >
        <div v-show="type == 1" id="qrcode" ref="qrcode"></div>
        <img v-show="type == 2" class="img" src="../assets/qrcode.jpg" alt="" />
      </div>
      <div class="rigth">
        <div @mouseenter="type = 1" @mouseleave="type = 3" class="item">
          <img src="../assets/shouji_.png" alt="" />
        </div>
        <div @mouseenter="type = 2" @mouseleave="type = 3" class="item">
          <img src="../assets/xiaochengxu_.png" alt="" />
        </div>
        <div class="item" @click="top">
          <img src="../assets/fanhuidingbu_.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  data() {
    return {
      type: 3,
      timer: null,
      positionX: 0,
      positionY: 0,
    };
  },
  methods: {
    //返回顶部
    top() {
      let that = this;
      that.timer = setInterval(function () {
        var scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        var ispeed = Math.floor(-scrollTop / 6);

        if (scrollTop == 0) {
          clearInterval(that.timer);
        }
        document.documentElement.scrollTop = document.body.scrollTop =
          scrollTop + ispeed;
      }, 30);
    },
    move(e) {
      let odiv = e.target; //获取目标元素

      //算出鼠标相对元素的位置

      let disX = e.clientX - odiv.offsetLeft;
      let disY = e.clientY - odiv.offsetTop;

      document.onmousemove = (e) => {
        //鼠标按下并移动的事件
        //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
        let left = e.clientX - disX;
        let top = e.clientY - disY;

        //绑定元素位置到positionX和positionY上面
        this.positionX = top;
        this.positionY = left;

        //移动当前元素
        odiv.style.right = left + "px";
        odiv.style.bottom = top + "px";
      };
      document.onmouseup = (e) => {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },
  },
  mounted() {
    //手机端二维码
    var qrcode = new QRCode(this.$refs.qrcode, {
      text: "https://mp.zpton.com/free", //页面地址 ,如果页面需要参数传递请注意哈希模式#
      width: 220,
      height: 220,
      colorDark: "#000000",
      colorLight: "#ffffff",
      correctLevel: QRCode.CorrectLevel.H,
    });
  },
};
</script>

<style lang="scss" scoped>
.advertising {
  position: fixed;
  display: flex;
  right: 50px;
  bottom: 50px;
  z-index: 99999999;

  .left {
    padding: 11px;
    box-sizing: border-box;
    position: relative;
    z-index: 99999999;
    width: 240px;
    height: 240px;
    background-color: #fff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    border-radius: 9px;
    .img {
      width: 220px;
      height: 220px;
    }
  }
  .active1 {
    &::after {
      position: absolute;
      z-index: 999998;
      right: -25px;
      top: 52px;
      transform-origin: 7px -5px;
      transform: rotate(135deg);
      display: inline-block;
      content: "";

      border-left: 10px solid #fff;
      border-top: 10px solid #fff;
      border-right: 10px solid transparent;
      border-bottom: 10px solid transparent;
    }
  }
  .active2 {
    &::after {
      position: absolute;
      z-index: 999998;
      right: -25px;
      top: 135px;
      transform-origin: 7px -5px;
      transform: rotate(135deg);
      display: inline-block;
      content: "";

      border-left: 10px solid #fff;
      border-top: 10px solid #fff;
      border-right: 10px solid transparent;
      border-bottom: 10px solid transparent;
    }
  }

  .rigth {
    padding: 0px 0px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-left: 15px;
    width: 100px;
    height: 240px;
    .item {
      cursor: pointer;
      width: 57px;
      height: 57px;
      background-color: #fff;
      box-shadow: 0px 2px 7px 0px rgba(79, 79, 79, 0.5);
      border-radius: 9px;
      img {
        display: block;
        margin: 12px auto;
      }
    }
  }
}
</style>
