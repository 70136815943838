import axios from "axios";
import store from "../store";
import router from "../router";
import {
  Message,
  MessageBox
} from "element-ui";
axios.defaults.retry = 5;
axios.defaults.retryDelay = 17000;
// 创建axios实例
const Http = axios.create({
  //baseURL: ajaxUrl, // api 的 base_url
  timeout: 15000, // 请求超时时间//加大请求时间数据过大
  headers: {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
  },
});
// request拦截器
Http.interceptors.request.use(
  (config) => {
    if (store.state.userInfo) {
      //config.headers['X-Token'] = store.state.userInfo.token // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config;
  },
  (error) => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);

// response 拦截器
Http.interceptors.response.use(
  (response) => {
    /**
     * code为非20000是抛错 可结合自己业务进行修改
     */
    const res = response.data;
    const codeReg = /^20\d+/;
    console.log(res, 'resresresresresres');

    //token失效
    if (res.errorCode == 2005) {
      Message('您已在其他地方登录');
      router.replace({
        path: "/login",
      });
      return false;
    }
    if (res.errorCode == 2004) {
      Message(res.message);

      router.replace({
        path: "/login",
      });
      return false;
    }
    if (!codeReg.test(response.status)) {

      return Promise.reject("error");
    } else {
      return response.data;
    }
  },
  (err) => {
    Message.error('网络繁忙请耐心等待');
    const config = err.config;
    console.log(config);
    // if (!config || !config.retry) return Promise.reject(err);
    // 手动给config添加当前重试次数的属性
    config.__retryCount = config.__retryCount || 0;
    // 达到上限时reject
    if (config.__retryCount >= config.retry) {
      return Promise.reject(err);
    }

    // 重试次数+1
    config.__retryCount += 1;

    // 定时器在达到重试间隔时resolve往下执行
    const backoff = new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, 6000);
    });
    // 返回一个promise，在定时器结束后重新调用timeOutRequest(config)发起请求，timeOutRequest之前创建的实例
    return backoff.then(() => Http(config));
  }
);

export default Http;