import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
Vue.use(VueRouter);
// headershow 控制header显示
// headershow1 控制header1显示
const routes = [
  {
    path: "/",
    name: "home",
    component: (resolve) => require(["@/views/home/index.vue"], resolve),
    meta: {
      title: "首页",
      keepAlive: false,
      headershow: true,
      headershow1: false,
      isLogin: false,
    },
  },
  // {
  //   path: "/text",
  //   name: "text",
  //   component: (resolve) => require(["@/views/text.vue"], resolve),
  //   meta: {
  //     title: "测试",
  //     keepAlive: false,
  //     headershow: false,
  //     headershow1: false,
  //     isLogin: false,
  //   },
  // },
  {
    path: "/applyText",
    name: "applyText",
    component: (resolve) => require(["@/views/applyText/index.vue"], resolve),
    meta: {
      title: "支付宝支付",
      keepAlive: false,
      headershow: false,
      headershow1: false,
      isLogin: false,
    },
  },
  {
    path: "/bindPhone",
    name: "bindPhone",
    component: (resolve) => require(["@/views/login/bindPhone.vue"], resolve),
    meta: {
      title: "绑定手机号",
      keepAlive: false,
      headershow: false,
      headershow1: false,
      isLogin: false,
    },
  },
  {
    path: "/login",
    name: "login",
    component: (resolve) => require(["@/views/login/login.vue"], resolve),
    meta: {
      title: "密码登录",
      keepAlive: false,
      headershow: false,
      headershow1: false,
      isLogin: false,
    },
  },
  {
    path: "/register",
    name: "register",
    component: (resolve) => require(["@/views/login/register.vue"], resolve),
    meta: {
      title: "注册",
      keepAlive: false,
      headershow: false,
      headershow1: false,
      isLogin: false,
    },
  },
  {
    path: "/loginPhone",
    name: "loginPhone",
    component: (resolve) => require(["@/views/login/loginPhone.vue"], resolve),
    meta: {
      title: "手机登陆",
      keepAlive: false,
      headershow: false,
      headershow1: false,
      isLogin: false,
    },
  },
  {
    path: "/setpassword",
    name: "setpassword",
    component: (resolve) => require(["@/views/login/setpassword.vue"], resolve),
    meta: {
      title: "设置密码",
      keepAlive: false,
      headershow: false,
      headershow1: false,
      isLogin: false,
    },
  },
  {
    path: "/wxlogin",
    name: "wxlogin",
    component: (resolve) => require(["@/views/login/wxlogin.vue"], resolve),
    meta: {
      title: "微信登录",
      keepAlive: false,
      headershow: false,
      headershow1: false,
      isLogin: false,
    },
  },
  {
    path: "/forgetthepassword",
    name: "forgetthepassword",
    component: (resolve) =>
      require(["@/views//login/forgetthepassword.vue"], resolve),
    meta: {
      title: "忘记密码",
      keepAlive: false,
      headershow: false,
      headershow1: false,
      isLogin: false,
    },
  },
  {
    path: "/solution",
    name: "solution",
    component: (resolve) => require(["@/views/solution/index.vue"], resolve),
    meta: {
      title: "产品及解决方案",
      keepAlive: false,
      headershow: true,
      headershow1: false,
      isLogin: false,
    },
  },
  {
    path: "/Institutional",
    name: "Institutional",
    component: (resolve) =>
      require(["@/views/Institutional/index.vue"], resolve),
    meta: {
      title: "机构云课堂",
      keepAlive: false,
      headershow: true,
      headershow1: false,
      isLogin: false,
    },
  },
  {
    path: "/privatizationdevelopment",
    name: "privatizationdevelopment",
    component: (resolve) =>
      require(["@/views/privatizationdevelopment/index.vue"], resolve),
    meta: {
      title: "开发私有化",
      keepAlive: false,
      headershow: true,
      headershow1: false,
      isLogin: false,
    },
  },
  {
    path: "/about",
    name: "about",
    component: (resolve) => require(["@/views/about/index.vue"], resolve),
    meta: {
      title: "关于我们",
      keepAlive: false,
      headershow: true,
      headershow1: false,
      isLogin: false,
    },
  },
  {
    path: "/contact",
    name: "contact",
    component: (resolve) => require(["@/views/contact/index.vue"], resolve),
    meta: {
      title: "联系我们",
      keepAlive: false,
      headershow: true,
      headershow1: false,
      isLogin: false,
    },
  },
  {
    path: "/information",
    name: "information",
    component: (resolve) => require(["@/views/information/index.vue"], resolve),
    meta: {
      title: "培训资讯",
      keepAlive: false,
      headershow: true,
      headershow1: false,
      isLogin: false,
    },
  },
  {
    path: "/informationdetail",
    name: "informationdetail",
    component: (resolve) =>
      require(["@/views/informationdetail/index.vue"], resolve),
    meta: {
      title: "培训资讯详细",
      keepAlive: false,
      headershow: true,
      headershow1: false,
      isLogin: false,
    },
  },
  {
    path: "/application",
    name: "application",
    component: (resolve) => require(["@/views/application/index.vue"], resolve),
    meta: {
      title: "应用案例",
      keepAlive: false,
      headershow: true,
      headershow1: false,
      isLogin: false,
    },
  },
  {
    path: "/business",
    name: "business",
    component: (resolve) => require(["@/views/business/index.vue"], resolve),
    meta: {
      title: "商务合作",
      keepAlive: false,
      headershow: true,
      headershow1: false,
      isLogin: false,
    },
  },
  {
    path: "/training",
    name: "training",
    component: (resolve) => require(["@/views/training/index.vue"], resolve),
    meta: {
      title: "培训课程",
      keepAlive: false,
      headershow: true,
      headershow1: false,
      isLogin: false,
    },
  },
  {
    path: "/allcourses",
    name: "allcourses",
    component: (resolve) => require(["@/views/allcourses/index.vue"], resolve),
    meta: {
      title: "全部课程",
      keepAlive: false,
      headershow: true,
      headershow1: false,
      isLogin: false,
    },
  },
  {
    path: "/coursedetails",
    name: "coursedetails",
    component: (resolve) =>
      require(["@/views/coursedetails.vue/index.vue"], resolve),
    meta: {
      title: "课程详细",
      keepAlive: false,
      headershow: true,
      headershow1: false,
      isLogin: true,
    },
  },
  {
    path: "/enterprisecurriculumdetails",
    name: "enterprisecurriculumdetails",
    component: (resolve) =>
      require(["@/views/enterprisecurriculumdetails/index.vue"], resolve),
    meta: {
      title: "企业课程详细",
      keepAlive: false,
      headershow: true,
      headershow1: false,
      isLogin: true,
    },
  },
  {
    path: "/Layout",
    name: "Layout",
    component: (resolve) => require(["@/views/Layout/Layout.vue"], resolve),
    children: [
      {
        path: "/Layout/Learningcenter",
        name: "Learningcenter",
        component: (resolve) =>
          require(["@/views/Learningcenter/index.vue"], resolve),
        meta: {
          title: "学习中心首页",
          keepAlive: false,
          headershow: false,
          headershow1: true,
          isLogin: true,
        },
      },
      {
        path: "/Layout/mynterpretation",
        name: "mynterpretation",
        component: (resolve) =>
          require(["@/views/mynterpretation/index.vue"], resolve),
        meta: {
          title: "我的班级",
          keepAlive: false,
          headershow: false,
          headershow1: true,
          isLogin: true,
        },
      },
      {
        path: "/Layout/reportonlearning",
        name: "reportonlearning",
        component: (resolve) =>
          require(["@/views/reportonlearning/index.vue"], resolve),
        meta: {
          title: "学习报告",
          keepAlive: false,
          headershow: false,
          headershow1: true,
          isLogin: true,
        },
      },
      {
        path: "/Layout/learningprogress",
        name: "learningprogress",
        component: (resolve) =>
          require(["@/views/learningprogress/index.vue"], resolve),
        meta: {
          title: "学习进度",
          keepAlive: false,
          headershow: false,
          headershow1: true,
          isLogin: true,
        },
      },
      {
        path: "/Layout/myfavorite",
        name: "myfavorite",
        component: (resolve) =>
          require(["@/views/myfavorite/index.vue"], resolve),
        meta: {
          title: "我的收藏",
          keepAlive: false,
          headershow: false,
          headershow1: true,
          isLogin: true,
        },
      },
      {
        path: "/Layout/myinformation",
        name: "myinformation",
        component: (resolve) =>
          require(["@/views/myinformation/index.vue"], resolve),
        meta: {
          title: "个人信息",
          keepAlive: false,
          headershow: false,
          headershow1: true,
          isLogin: true,
        },
      },
      {
        path: "/Layout/myset",
        name: "myset",
        component: (resolve) => require(["@/views/myset/index.vue"], resolve),
        meta: {
          title: "账号设置",
          keepAlive: false,
          headershow: false,
          headershow1: true,
          isLogin: true,
        },
      },
      {
        path: "/Layout/message",
        name: "message",
        component: (resolve) => require(["@/views/message/index.vue"], resolve),
        meta: {
          title: "消息中心",
          keepAlive: false,
          headershow: false,
          headershow1: true,
          isLogin: true,
        },
      },
    ],
  },

  {
    path: "/Layoutstudy",
    name: "Layoutstudy",
    component: (resolve) => require(["@/views/Layoutstudy/index.vue"], resolve),
    children: [
      {
        path: "/Layoutstudy/studyLecture",
        name: "studyLecture",
        component: (resolve) =>
          require(["@/views/studyLecture/index.vue"], resolve),
        meta: {
          title: "听课",
          keepAlive: false,
          headershow: false,
          headershow1: true,
          isLogin: true,
        },
      },
      {
        path: "/Layoutstudy/know",
        name: "know",
        component: (resolve) => require(["@/views/know/index.vue"], resolve),
        meta: {
          title: "学知",
          keepAlive: false,
          headershow: false,
          headershow1: true,
          isLogin: true,
        },
      },
      {
        path: "/Layoutstudy/knowdetail",
        name: "knowdetail",
        component: (resolve) =>
          require(["@/views/knowdetail/index.vue"], resolve),
        meta: {
          title: "学知详细",
          keepAlive: false,
          headershow: false,
          headershow1: true,
          isLogin: true,
        },
      },
      {
        path: "/Layoutstudy/studyVideo",
        name: "studyVideo",
        component: (resolve) =>
          require(["@/views/studyVideo/index.vue"], resolve),
        meta: {
          title: "视频学习",
          keepAlive: false,
          headershow: false,
          headershow1: true,
          isLogin: true,
        },
      },
      {
        path: "/Layoutstudy/studyVideoCopy",
        name: "studyVideo",
        component: (resolve) =>
          require(["@/views/studyVideoCopy/index.vue"], resolve),
        meta: {
          title: "视频学习",
          keepAlive: false,
          headershow: false,
          headershow1: true,
          isLogin: true,
        },
      },
      {
        path: "/Layoutstudy/studyVideoexamine",
        name: "studyVideoexamine",
        component: (resolve) =>
          require(["@/views/studyVideoexamine/index.vue"], resolve),
        meta: {
          title: "视频学习检查",
          keepAlive: false,
          headershow: false,
          headershow1: true,
          isLogin: true,
        },
      },
      {
        path: "/Layoutstudy/studyVideoexamine1",
        name: "studyVideoexamine",
        component: (resolve) =>
          require(["@/views/studyVideoexamine1/index.vue"], resolve),
        meta: {
          title: "视频学习检查",
          keepAlive: false,
          headershow: false,
          headershow1: true,
          isLogin: true,
        },
      },
      {
        path: "/Layoutstudy/wyystudyVideo",
        name: "wyystudyVideo",
        component: (resolve) =>
          require(["@/views/studywyyVideo/index.vue"], resolve),
        meta: {
          title: "网易云视频学习",
          keepAlive: false,
          headershow: false,
          headershow1: true,
          isLogin: true,
        },
      },
      {
        path: "/Layoutstudy/studywyyVideoCopy",
        name: "studywyyVideoCopy",
        component: (resolve) =>
          require(["@/views/studywyyVideoCopy/index.vue"], resolve),
        meta: {
          title: "网易云视频学习",
          keepAlive: false,
          headershow: false,
          headershow1: true,
          isLogin: true,
        },
      },
      {
        path: "/Layoutstudy/writing",
        name: "writing",
        component: (resolve) => require(["@/views/writing/index.vue"], resolve),
        meta: {
          title: "练答",
          keepAlive: false,
          headershow: false,
          headershow1: true,
          isLogin: true,
        },
      },
      {
        path: "/Layoutstudy/writingexercise",
        name: "writingexercise",
        component: (resolve) =>
          require(["@/views/writingexercise/index.vue"], resolve),
        meta: {
          title: "练答练习",
          keepAlive: false,
          headershow: false,
          headershow1: true,
          isLogin: true,
        },
      },
      {
        path: "/Layoutstudy/practiceresults",
        name: "practiceresults",
        component: (resolve) =>
          require(["@/views/practiceresults/index.vue"], resolve),
        meta: {
          title: "练习结果页面",
          keepAlive: false,
          headershow: false,
          headershow1: true,
          isLogin: true,
        },
      },
      {
        path: "/Layoutstudy/examination",
        name: "examination",
        component: (resolve) =>
          require(["@/views/examination/index.vue"], resolve),
        meta: {
          title: "考试",
          keepAlive: false,
          headershow: false,
          headershow1: true,
          isLogin: true,
        },
      },
      {
        path: "/Layoutstudy/answer",
        name: "answer",
        component: (resolve) => require(["@/views/answer/index.vue"], resolve),
        meta: {
          title: "答疑",
          keepAlive: false,
          headershow: false,
          headershow1: true,
          isLogin: true,
        },
      },
      {
        path: "/Layoutstudy/quiz",
        name: "quiz",
        component: (resolve) => require(["@/views/quiz/index.vue"], resolve),
        meta: {
          title: "提问",
          keepAlive: false,
          headershow: false,
          headershow1: true,
          isLogin: true,
        },
      },
      {
        path: "/Layoutstudy/quizdetail",
        name: "quizdetail",
        component: (resolve) =>
          require(["@/views/quizdetail/index.vue"], resolve),
        meta: {
          title: "提问详细",
          keepAlive: false,
          headershow: false,
          headershow1: true,
          isLogin: true,
        },
      },
      {
        path: "/Layoutstudy/survey",
        name: "survey",
        component: (resolve) => require(["@/views/survey/index.vue"], resolve),
        meta: {
          title: "问卷调查",
          keepAlive: false,
          headershow: false,
          headershow1: true,
          isLogin: true,
        },
      },
      {
        path: "/Layoutstudy/testhistory",
        name: "testhistory",
        component: (resolve) => require(["@/views/testhistory/index.vue"], resolve),
        meta: {
          title: "考试历史",
          keepAlive: false,
          headershow: false,
          headershow1: true,
          isLogin: true,
        },
      },
      {
        path: "/Layoutstudy/testhistorydetail",
        name: "testhistory",
        component: (resolve) => require(["@/views/testhistorydetail/index.vue"], resolve),
        meta: {
          title: "考试详细",
          keepAlive: false,
          headershow: false,
          headershow1: true,
          isLogin: true,
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history", //hash
  history: true,
  strict: process.env.NODE_ENV !== "production",
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.name !== "login" || to.name !== "loginPhone") {
    if (
      to.name == "register" ||
      to.name == "password" ||
      to.name == "wxlogin"
    ) {
      next();
    } else {
      //studyLayout/
      if (to.name == "free" || to.name == "/schoolHome") {
        store.commit("SET_STUDYTYPE", "1");
      }
      //需要登录拦截
      if (to.meta.isLogin) {
        if (!store.state.userInfo) {
          store.commit("SET_RESETUSER", "");
          next({
            path: "/login",
            query: { type: 1 }, //标记是登录拦截的，登录之后后退到当前页
          });
        } else {
          next();
        }
      } else {
        next();
      }
    }
  } else {
    store.commit("SET_RESETUSER", "");
    next();
  }
});
export default router;
