import api from "@/libs/reset.js";
import md5 from "js-md5";
const udao = "udao20171024"; //md5公共的加密混入字符串
import store from "../store";
export default {
  //用户名登录
  async getLogin({
    commit
  }, param) {
    const data = {
      username: param.username,
      pwd: md5(param.pwd),
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Login/Login", data);
    if (res.code == 200) {
      commit("SET_USERINFO", res.data);
    }
    return res;
  },
  //第三方登录绑定手机号
  async getBound({
    commit
  }, param) {
    const data = {
      ...param,
      code: md5(udao),
    };
    const res = await api.post("/index/Login/Bound", data);
    if (res.code == 200) {
      commit("SET_USERINFO", res.data);
    }
    return res;
  },
  //pc微信登录
  async getweChatLogin({
    commit
  }, param) {
    const data = {
      ...param,
    };
    const res = await api.post("/index/Login/weChatLogin", data);
    if (res.code == 200) {
      commit("SET_USERINFO", res.data);
    }
    return res;
  },
  //退出登录
  async getLoginOut({
    commit
  }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    commit("SET_SCHOOL", "");
    const res = await api.post("/index/Login/LoginOut", data);
    return res;
  },
  async getClassHour({
    commit
  }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Mine/ClassHour", data);
    return res;
  },
  //上传图片
  async getPictureUpload({
    commit
  }, param) {
    const res = await api.post("/index/Mine/PictureUpload", param);
    return res;
  },
  //提问列表
  async getMineQuestionList({
    commit
  }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Mine/QuestionList", data);
    return res;
  },
  //提问
  async getMinePutQuestion({
    commit
  }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Mine/PutQuestion", data);
    return res;
  },
  //提问详情
  async getMineQuestionDetail({
    commit
  }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Mine/QuestionDetail", data);
    return res;
  },
  //修改头像
  async getEditInfo({
    commit
  }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Mine/EditInfo", data);
    return res;
  },
  //首页--选课(免费，付费，实训)
  async getIndex({
    commit
  }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index", data);
    return res;
  },
  //首页更多课程接口
  async getMoreList({
    commit
  }, param) {
    const data = {
      ...param,
      code: md5(udao),
    };
    const res = await api.post("/index/Pub/MoreList", data);
    return res;
  },
  //首页分类上拉下拉
  async getProClass({
    commit
  }, param) {
    const data = {
      ...param,
      code: md5(udao),
    };
    const res = await api.post("/index/Pub/ProClass", data);
    return res;
  },
  //课程详情接口
  async getClassDet({
    commit
  }, param) {
    const data = {
      ...param,
      code: md5(udao),
    };
    const res = await api.post("/index/Pub/ClassDet", data);
    return res;
  },
  //详情---未购买课程（目录）
  async getCatalog({
    commit
  }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Study/Catalog", data);
    return res;
  },
  //个人中心---取消收藏
  async getCancelCollection({
    commit
  }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Mine/CancelCollection", data);
    return res;
  },
  //收藏课程
  async getAddCollection({
    commit
  }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Mine/AddCollection", data);
    return res;
  },

  //个人中心---我的课程
  async getMyLesson({
    commit
  }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Mine/MyLesson", data);
    if (res.code == 200) {
      commit("SET_MYLESSONLIST", res.data);
    }
    return res;
  },
  //个人中心---学习报告接口
  async getPresentation({
    commit
  }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Mine/Presentation", data);
    return res;
  },
  //个人中心---学习进度详情
  async getMinePaceDetail({
    commit
  }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Mine/PaceDetail", data);
    return res;
  },
  //个人中心---学习进度
  async getMinePace({
    commit
  }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Mine/Pace", data);
    //过滤脏数据
    if (res.msg != "暂无数据!") {
      let str = [];
      str = res.data.filter((item, index) => {
        return item.id;
      });
      res.data = str;
    }

    return res;
  },
  async getsurvey({
    commit
  }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Study/survey", data);
    return res;
  },
  async getsubAnswer({
    commit
  }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Study/subAnswer", data);
    return res;
  },
  //获取验证码
  async getVerify({
    commit
  }, param) {
    const data = {
      ...param,
      code: md5(udao),
    };
    const res = await api.post("/index/Login/Verify", data);
    return res;
  },
  //注册接口
  async getRegiser({
    commit
  }, param) {
    const data = {
      ...param,
      password: md5(param.password),
      code: md5(udao),
    };
    const res = await api.post("/index/Login/Regiser", data);
    return res;
  },
  //个人中心---我的收藏
  async getCollection({
    commit
  }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Mine/Collection", data);
    return res;
  },
  //修改密码--忘记密码
  async getChangePassword({
    commit
  }, param) {
    const data = {
      ...param,
      password: md5(param.password),
      code: md5(udao),
    };
    const res = await api.post("/index/Login/ChangePassword", data);
    return res;
  },
  //班级详情接口
  async getclassDetail({
    commit
  }, param) {
    const data = {
      ...param,
      code: md5(udao),
    };
    const res = await api.post("/index/index/classDetail", data);
    return res;
  },
  //学习--音频
  async getAudioList({
    commit
  }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Study/AudioList", data);
    return res;
  },
  //签到接口
  async getSignIn({
    commit
  }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Study/SignIn", data);
    return res;
  },
  //学习---目录
  async getClassCharter({
    commit
  }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Study/ClassCharter", data);
    return res;
  },
  ///学习---已购买课程H5学习详情
  async getCharLer({
    commit
  }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Study/CharLer", data);
    return res;
  },
  //实训考点接口
  async getKnowledgeList({
    commit
  }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Study/KnowledgeList", data);
    return res;
  },
  //音频视频回调接口--用于记录学习
  async getVideoAudioCallback({
    commit
  }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Study/VideoAudioCallback", data);
    return res;
  },
  //学习---视频
  async getVideoList({
    commit
  }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Study/VideoList", data);
    return res;
  },
  //实训考点详情接口
  async getKnowledgeDetail({
    commit
  }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Study/KnowledgeDetail", data);
    return res;
  },
  //修改实训考点状态
  async getSetKnowledgeStatus({
    commit
  }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Study/SetKnowledgeStatus", data);
    return res;
  },
  //练答---章节练习列表
  async getChapterCatalog({
    commit
  }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Examination/ChapterCatalog", data);
    return res;
  },
  //考试封面
  async getMockCover({
    commit
  }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Examination/MockCover", data);
    return res;
  },
  //答题详情 1.模拟考试 5.章节练习 6.总题库
  async getMakeQuestion({
    commit
  }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Examination/MakeQuestion", data);
    return res;
  },
  //该接口用于用户章节 题库 模拟考试 记录学员的答案
  async getCalculateSectionTest({
    commit
  }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Examination/CalculateSectionTest", data);
    return res;
  },
  //错题
  async getErrorSubject({
    commit
  }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Examination/ErrorSubject", data);
    return res;
  },
  //错题回调
  async getErrorBankResults({
    commit
  }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Examination/ErrorBankResults", data);
    return res;
  },
  //记录成绩
  async getSectionTestScores({
    commit
  }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Examination/SectionTestScores", data);
    return res;
  },
  //免费下单接口
  async getFreePurchase({
    commit
  }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Order/FreePurchase", data);
    return res;
  },
  //pc端支付
  async getpcGenerateOrder({
    commit
  }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Order/pc_GenerateOrder", data);
    return res;
  },
  // 旧接口 新闻列表
  async getNews1({
    commit
  }, param) {
    const data = {
      ...param,
    };
    const res = await api.post("/index/News/newsList", data);
    return res;
  },
  // 旧接口 新闻详细
  async getnewsDetail1({
    commit
  }, param) {
    const data = {
      ...param,
    };
    const res = await api.post("/index/News/newsDetail", data);
    return res;
  },
  // 新接口 首页
  async getindex_x({
    commit
  }, param) {
    const data = {
      ...param,
    };
    const res = await api.postB("/index/index", data);
    return res;
  },
  // 新接口 新闻列表
  async getNews({
    commit
  }, param) {
    const data = {
      ...param,
    };
    const res = await api.postB("/index/News/index", data);
    return res;
  },
  // 新接口 新闻详细
  async getnewsDetail({
    commit
  }, param) {
    const data = {
      ...param,
    };
    const res = await api.postB("/index/News/newsDetail", data);
    return res;
  },
  // 新接口 加入我们
  async getinsJoinUs({
    commit
  }, param) {
    const data = {
      ...param,
    };
    const res = await api.post("/index/Join_us/insJoinUs", data);
    return res;
  },
  //新接口登录 
  async getLogin_x({
    commit
  }, param) {
    const data = {
      username: param.username,
      password: md5(param.password),

    };
    const res = await api.postB("/index/Login/passwordLogin", data);
    if (res.successCode == 200) {
      let num = Math.round(Math.random() * 42);
      commit("STE_HUMAN", num);
      commit("SET_USERINFO", res.responseBody);

    }
    return res;
  },
  //新接口短信验证码接口
  async getAlibabaMessage_x({
    commit
  }, param) {
    const data = {
      ...param
    };
    const res = await api.postB("/index/Login/getAlibabaMessage", data);
    return res;
  },
  //新接口首页--选课(免费，付费，实训)
  async getphoneIndex_x({
    commit
  }, param) {
    commit("SET_HOME", "");
    const data = {
      ...param,
    };
    const res = await api.getB("/index/index/phoneIndex", data);

    if (res.successCode == 200) {
      commit("SET_HOME", res.responseBody);
    }
    return res;
  },
  //新接口更多直播接口
  async getmoreLive_x({
    commit
  }, param) {
    const data = {
      ...param
    };
    const res = await api.postB("/index/index/moreLive", data);
    return res;
  },
  //新接口班级列表接口
  async getclassList_x({
    commit
  }, param) {
    const data = {
      ...param
    };
    const res = await api.postB("/index/index/classList", data);
    return res;
  },
  //新接口我的班级列表接口
  async getmyClass_x({
    commit
  }, param) {
    const data = {
      params: param
    };
    const res = await api.postC("/index/Mine/myClass", data);
    return res;
  },
  //新接口听看列表接口
  async getknowledgeList_x({
    commit
  }, param) {

    const data = {
      params: param
    };
    const res = await api.postC("/index/Study/knowledgeList", data);
    return res;
  },
  //新接口添加学习记录接口
  async getinsStudyRecord_x({
    commit
  }, param) {
    const data = {
      params: param
    };
    const res = await api.postC("/index/Study/insStudyRecord", data);
    return res;
  },
  //新接口h5列表接口
  async getHTML5List_x({
    commit
  }, param) {

    const data = {
      params: param
    };
    const res = await api.postC("/index/Study/HTML5List", data);
    return res;
  },
  //新接口班级统计接口
  async getstudyReport_x({
    commit
  }, param) {
    const data = {
      params: param
    };
    const res = await api.postC("/index/Mine/studyReport", data);
    return res;
  },
  //新接口章节列表接口
  async getchapterList_x({
    commit
  }, param) {
    const data = {
      params: param
    };
    const res = await api.postC("/index/Examination/chapterList", data);
    return res;
  },
  //新接口账号设置接口
  async getsetUp_x({
    commit
  }, param) {
    const data = {
      params: {
        ...param,
        password: md5(param.password),
      }

    };
    const res = await api.postC("/index/Mine/setUp", data);
    return res;
  },
  //新接口统计报告接口
  async getstudyReportFromDay_x({
    commit
  }, param) {
    const data = {
      params: param
    };
    const res = await api.postC("/index/Mine/studyReportFromDay", data);
    return res;
  },
  //新接口h5详情接口
  async getHTML5Detail_x({
    commit
  }, param) {
    const data = {
      params: param
    };
    const res = await api.postC("/index/Study/HTML5Detail", data);
    return res;
  },
  //新接口我的收藏接口
  async getmyCollection_x({
    commit
  }, param) {
    const data = {
      params: param
    };
    const res = await api.postC("/index/Mine/myCollection", data);
    return res;
  },

  //新接口退出登录接口
  async getsignOut_x({
    commit
  }, param) {
    const data = {
      params: param
    };
    const res = await api.postC("/index/Mine/signOut", data);
    return res;
  },
  //新接口头像上传接口
  async getpictureUpload_x({
    commit
  }, param) {
    const data = {
      ...param
    };
    const res = await api.postB("/index/Pub/pictureUpload", data);
    return res;
  },
  //新接口修改个人信息接口
  async getsaveSelfInformation_x({
    commit
  }, param) {
    const data = {
      params: param
    };
    const res = await api.postC("/index/Mine/saveSelfInformation", data);
    return res;
  },
  //新接班级详情接口
  async getclassDetail_x({
    commit
  }, param) {
    const data = {
      ...param
    };
    const res = await api.postB("/index/index/classDetail", data);
    return res;
  },
  //新接口课程收藏接口
  async getinsCollection_x({
    commit
  }, param) {
    const data = {
      params: param
    };
    const res = await api.postC("/index/Mine/insCollection", data);
    return res;
  },
  //新接口取消收藏接口
  async getremoveCollection_x({
    commit
  }, param) {
    const data = {
      params: param
    };
    const res = await api.postC("/index/Mine/removeCollection", data);
    return res;
  },
  //新接口订单列表接口 
  async getOrderindex_x({
    commit
  }, param) {
    const data = {
      params: param
    };
    const res = await api.postC("/index/Order/index", data);
    return res;
  },
  //新接口注册接口
  async getregister_x({
    commit
  }, param) {
    const data = {
      ...param,
      password: md5(param.password),
    };
    const res = await api.postB("/index/Login/register", data);
    return res;
  },
  //新接口修改密码接口
  async getupPassword_x({
    commit
  }, param) {
    const data = {
      ...param,
      password: md5(param.password),
    };
    const res = await api.postB("/index/Login/upPassword", data);
    return res;
  },
  //新接口问卷调查接口  
  async getquestionnaire_x({
    commit
  }, param) {
    const data = {
      params: param
    };
    const res = await api.postC("/index/Questionnaire/questionnaire", data);
    return res;
  },
  //新接口答题回调 
  async getinsQuestionnaireResult_x({
    commit
  }, param) {
    const data = {
      params: param
    };
    const res = await api.postC("/index/Questionnaire/insQuestionnaireResult", data);
    return res;
  },
  //新接口搜索接口
  async getsearch_x({
    commit
  }, param) {
    const data = {
      ...param,
    };
    const res = await api.postB("/index/index/search", data);
    return res;
  },
  //新接口直播链接接口
  async getRoomUrl_x({
    commit
  }, param) {
    const data = {
      params: param
    };
    const res = await api.postC("/index/Bai_jia_yun_api/getRoomUrl", data);
    return res;
  },
  //新接口支付接口
  async getweChartPay_x({
    commit
  }, param) {
    const data = {
      params: param
    };
    const res = await api.postC("/index/Order/weChartPay", data);
    return res;
  },
  //新接口答疑列表接口
  async getasking_x({
    commit
  }, param) {
    const data = {
      params: param
    };
    const res = await api.postC("/index/Mine/asking", data);
    return res;
  },
  //新接口添加答疑接口 
  async getinsAsking_x({
    commit
  }, param) {
    const data = {
      params: param
    };
    const res = await api.postC("/index/Mine/insAsking", data);
    return res;
  },
  //新接口微信登录接口 
  async getwxLogin_x({
    commit
  }, param) {
    const data = {
      ...param,
    };
    const res = await api.postB("/index/Login/wxLogin", data);
    if (res.successCode == 200) {
      commit("SET_USERINFO", res.responseBody);
    }
    return res;
  },
  //新接口获取题目
  async geSubject_x({
    commit
  }, param) {
    const data = {
      params: param
    };
    const res = await api.postC("/index/Examination/getSubject", data);
    return res;
  },
  //新接口做题回调接口
  async geinsSubjectRecord_x({
    commit
  }, param) {
    const data = {
      params: param
    };
    const res = await api.postC("/index/Examination/insSubjectRecord", data);
    return res;
  },
  //新接口扫码接口
  async getSignPackage_x({
    commit
  }, param) {
    const data = {
      ...param,
    };
    const res = await api.postB("/index/We_chat/getSignPackage", data);
    return res;
  },
  //新接口获取错题接口
  async getErrorSubject_x({
    commit
  }, param) {
    const data = {
      params: param
    };
    const res = await api.postC("/index/Examination/getErrorSubject", data);
    return res;
  },
  //新接口错题回调接口
  async getjudgeCorrect_x({
    commit
  }, param) {
    const data = {
      params: param
    };
    const res = await api.postC("/index/Examination/judgeCorrect", data);
    return res;
  },
  //新接口综合测试接口
  async getcomprehensiveCover_x({
    commit
  }, param) {
    const data = {
      params: param
    };
    const res = await api.postC("/index/Examination/comprehensiveCover", data);
    return res;
  },
  //新接口本次测试结果接口
  async getreturnTestResults_x({
    commit
  }, param) {
    const data = {
      params: param
    };
    const res = await api.postC("/index/Examination/returnTestResults", data);
    return res;
  },
  //新接口签到接口
  async getSignIn_x({
    commit
  }, param) {
    const data = {
      params: param
    };
    const res = await api.postC("/index/Study/SignIn", data);
    return res;
  },
  //新接口绑定登录接口
  async getbinding_x({
    commit
  }, param) {
    const data = {
      ...param,
    };
    const res = await api.postB("/index/Login/binding", data);

    return res;
  },
  //返回做题详情接口
  async getreturnSubjectDetail_x({
    commit
  }, param) {
    const data = {
      params: param
    };
    const res = await api.postC("/index/Examination/returnSubjectDetail", data);
    return res;
  },
  // 网站支付
  async getpcPay({
    commit
  }, param) {
    const data = {
      params: param
    };
    const res = await api.postC("/index/Order/pcPay", data);
    return res;
  },
  // 免费班级
  async getfreeOrder({
    commit
  }, param) {
    const data = {
      params: param
    };
    const res = await api.postC("/index/Order/freeOrder", data);
    return res;
  },
  //Order/AppletPay 小程序支付 未写
  //AppletsLoginy 小程序登陆 未写
  //新接口验证码登录
  async getverificationCodeLogin_x({
    commit
  }, param) {
    const data = {
      ...param,
    };
    const res = await api.postB("/index/Login/verificationCodeLogin", data);
    if (res.successCode == 200) {
      let num = Math.round(Math.random() * 42);
      commit("STE_HUMAN", num);
      commit("SET_USERINFO", res.responseBody);
    }
    return res;
  },
  //离线记录时长、
  async screenCuttingDuration_x({
    commit
  }, param) {
    const data = {
      params: param
    };
    const res = await api.postC("/index/Study/screenCuttingDuration", data);
    return res;
  },
  async fiveCount_x({
    commit
  }, param) {
    const data = {
      params: param
    };
    const res = await api.postC("/index/Mine/fiveCount", data);
    return res;
  },
  //真实性核验
  async getsmsVerificationr({
    commit
  }, param) {
    const data = {
      params: param
    };
    const res = await api.postC("/index/Study/smsVerification", data);
    return res;
  },
  //活跃度检测
  async getactivityDetection({
    commit
  }, param) {
    const data = {
      params: param
    };
    const res = await api.postC("/index/Study/activityDetection", data);
    return res;
  },
  //人脸识别
  async faceAuth({
    commit
  }, param) {
    const data = {
      ...param,
    };
    const res = await api.postC("/index/face_recognition/faceAuth", data);
    return res;
  },
  //获取网易云视频签名
  async getVideoSign({
    commit
  }, param) {
    const data = {
      params: param
    };
    const res = await api.postC("/index/Study/getVideoSign", data);
    return res;
  },

  async ChapterSectionList({
    commit
  }, param) {
    const data = {
      params: param
    };
    const res = await api.postC("/index/Study/ChapterSectionList", data);
    return res;
  },

  async getTodayStudyTime({
    commit
  }, param) {
    const data = {
      params: param
    };
    const res = await api.postC("/index/Study/getTodayStudyTime", data);
    return res;
  },
  //删除缓存
  async getrmCache({
    commit
  }, param) {
    const data = {
      ...param
    };
    const res = await api.postB("/index/Pub/rmCache", data);
    return res;
  },
  //查询视频观看进度
  async getVideoSpeed({
    commit
  }, param) {
    const data = {
      params: param
    };
    const res = await api.postC("/index/Mine/getVideoSpeed", data);
    return res;
  },
  //查询人脸识别
  async getFaceRecognition({
    commit
  }, param) {
    const data = {
      params: param
    };
    const res = await api.postC("/index/Mine/getFaceRecognition", data);
    return res;
  },
  // //file上传
  // async uploadImage({ commit }, param) {
  //   const data = {
  //     params: param
  //   };
  //   const res = await api.postC("/index/Pub/uploadImage", data);
  //   return res;
  // },
  async getUploadImg({
    commit
  }, param) {
    const res = await api.upload("/index/Pub/uploadImage", param);
    return res;
  },
  //重新还有多少未看完
  async cumulativeTime({
    commit
  }, param) {
    const data = {
      params: param
    };
    const res = await api.postC("/index/Study/cumulativeTime", data);
    return res;
  },
  //重新还有多少未看完
  async getFaceRecognition1({
    commit
  }, param) {
    const data = {
      params: param
    };
    const res = await api.postC("/index/Mine/getFaceRecognition1", data);
    return res;
  },
};